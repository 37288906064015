<template>
  <div class="frame">
    <el-row :gutter="20">
      <el-col :span="16">
        <div class="article kefan">
          <el-page-header @back="goBack" content="详情页面"> </el-page-header>
          <el-divider></el-divider>
          <!-- <div class="thead"> -->
          <!-- <el-tag type="success" style="margin-right: 10px"
              >#{{ article_data.yuanqu }}</el-tag
            >
            <el-tag type="primary">#{{ article_data.fenlei }}</el-tag> -->
          <!-- </div> -->
          <div
            class="thead"
            :style="{
              backgroundImage: 'url(' + t_img + ')',
              bacgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
            }"
          >
            {{ article_data.yuanqu }}
          </div>
          <div
            class="fhead"
            :style="{
              backgroundImage: 'url(' + f_img + ')',
              bacgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
            }"
          >
            <p>{{ article_data.fenlei }}</p>
          </div>
          <h3>{{ article_data.title }}</h3>
          <div class="tool">
            <span class="dz" @click="judgegood(click)">
              <i v-if="isgood == true" class="el-icon-star-on"></i>
              <i v-else class="el-icon-star-off"></i>
              <span v-if="isgood == false">{{goodnums}}</span>
              <span v-else class="green">{{goodnums}}</span>
            </span>

            <i class="el-icon-view"></i>
            <span class="v-data">{{view}}</span>
          </div>
          <div class="info">
            <p>作者：{{ article_data.author }} [{{ article_data.bumen }}]</p>
            <p class="time">{{ article_data.date }}</p>
          </div>
          <!-- <el-tag type="primary">{{ article_data.fenlei }}</el-tag> -->
          <div class="ct">
            <h4>事情经过/解决办法</h4>
            <el-divider></el-divider>
            <p v-if="article_data.a !== ''">{{ article_data.a }}</p>
            <p v-else>
              <el-empty description="暂无内容"></el-empty>
            </p>
            <h4>部门/园区经验分享</h4>
            <el-divider></el-divider>
            <p v-if="article_data.b !== ''">{{ article_data.b }}</p>
            <p v-else>
              <el-empty description="暂无内容"></el-empty>
            </p>
            <h4>分管领导点评</h4>
            <el-divider></el-divider>
            <p v-if="article_data.c !== ''">{{ article_data.c }}</p>
            <p v-else>
              <el-empty description="暂无内容"></el-empty>
            </p>
            <h4>公司董事长点评</h4>
            <el-divider></el-divider>
            <p v-if="article_data.d !== ''">{{ article_data.d }}</p>
            <p v-else>
              <el-empty description="暂无内容"></el-empty>
            </p>
          </div>
          <!-- <div class="tool">
             <el-row :gutter="100">
                <el-col :span="12" class="sc">
                    <i class="el-icon-star-on"></i>
                    <span>12</span>
                </el-col>
                <el-col :span="12" class="view">
                    <i class="el-icon-view"></i>
                    <span>112</span>
                </el-col>
             </el-row>
          </div> -->
        </div>
      </el-col>
      <el-col :span="8">
        <div class="pl kefan">
          <div class="pl_title">
            <h4>评论</h4>
            <el-divider></el-divider>
          </div>
          <div v-for="(item, index) in pldata" :key="index" class="pldata">
            <div class="pl_head">
              <el-avatar
                v-if="item.avatar !== null"
                :src="item.avatar"
              ></el-avatar>
              <el-avatar v-else>{{ item.nickname }}</el-avatar>
              <p style="margin-left:0.5rem;color:#9F9F9F">{{ item.nickname }}</p>
              <div class="datetime">
                {{ item.timelog }}
              </div>
            </div>
            <div class="pl_content">
              <p>{{ item.content }}</p>
            </div>
            <el-divider></el-divider>
          </div>
          <div v-if="pldata.length == 0">
            <el-empty description="暂无内容"></el-empty>
          </div>
          <div style="margin: 20px 0"></div>
          <div class="addhf">
            <h4>添加评论</h4>
            <el-divider></el-divider>
          </div>
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="textarea"
            maxlength="200"
            show-word-limit
          >
          </el-input>
          <div class="plsubmit">
            <el-button
              type="success"
              size="small"
              style="margin-top: 5px"
              @click="submitpl(click)"
              >发布评论</el-button
            >
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      artice_id: this.$route.query.id,
      article_data: {},
      t_img: this.$img + "yuanqu.png",
      f_img: this.$img + "fal.png",
      isgood: false,
      click: true,
      initclick: false,
      textarea: "",
      pldata: [],
      goodnums:0,
      view:0
    };
  },
  methods: {
    getarticledata(id) {
      axios({
        method: "get",
        url: this.$host + this.$api.article + "?id=" + id,
      }).then((res) => {
        console.log(res.data);
        this.article_data = res.data;
      });
    },
    addview(){
      axios({
        method:'post',
        url:this.$host+this.$api.view,
        data: {
          uid: JSON.parse(localStorage.getItem("info")).uid,
          aid: this.artice_id,
        }
      }).then((res)=>{
        this.view = res.data
      })
    },
    goBack() {
      this.$router.back(); //后退
    },
    judgegood(click) {
      axios({
        method: "post",
        url: this.$host + this.$api.good,
        data: {
          uid: JSON.parse(localStorage.getItem("info")).uid,
          aid: this.artice_id,
          click: click,
        },
      }).then((res) => {
        if (res.data.result == "ok") {
          this.isgood = true;
          this.goodnums = res.data.num
        } else {
          this.isgood = false;
          this.goodnums = res.data.num
        }
      });
    },
    submitpl(click) {
      if (this.textarea == "" && click == true) {
        this.$notify.error("不能回复空内容");
      } else {
        axios({
          method: "post",
          url: this.$host + this.$api.pinglun,
          data: {
            aid: this.artice_id,
            uid: JSON.parse(localStorage.getItem("info")).uid,
            content: this.textarea,
            click: click,
          },
        }).then((res) => {
          console.log(res.data);
          if (res.data[0] == "success") {
            this.pldata = res.data.data;
            this.$notify.success("评论成功！");
            this.textarea = "";
          } else {
            this.pldata = res.data.data;
          }
        });
      }
    },
  },
  mounted() {
    this.addview();
    this.getarticledata(this.artice_id);
    this.judgegood(this.initclick);
    this.submitpl(this.initclick);
  },
  // watch: {
  //     $route(to){
  //         console.log(to)
  //     }
  // },
};
</script>
<style scoped>
.pldata {
  margin-top: 10px;
}
.pldata .el-divider--horizontal {
  margin: 5px 0 0 10px;
}
.pl_head {
  display: flex;
  align-items: center;
}
.pl_head .datetime {
  font-size: 14px;
  color: #9f9f9f;
  position: absolute;
  right: 2%;
}
.pl_content {
  /* text-indent: 2em; */
  font-size: 14px;
  color: #555050;
  font-weight: 535;
  line-height: 30px;
}
.article h3 {
  text-align: center;
}
.dz .el-icon-star-off:hover {
  cursor: pointer;
  font-size: 24px;
  transition: all 0.1s linear;
  /* color: #4fc08d;
  text-shadow: 0 0 5px #4fc08d, 0 0 5px #4fc08d, 0 0 5px #4fc08d; */
}
.tool {
  text-align: right;
}
.green {
  color: #4fc08d;
  transition: all 0.3s ease;
}
.tool .el-icon-star-off {
  font-size: 20px;
  transition: all 0.3s ease;
}
.tool .el-icon-star-on {
  font-size: 20px;
  color: #4fc08d;
  transition: all 0.3s ease;
}
.tool .el-icon-view {
  margin-left: 2em;
  /* font-size: 24px; */
}
.el-icon-view,
.v-data {
  color: #9f9f9f;
}
.info {
  text-align: right;
  font-size: 12px;
  color: #9f9f9f;
}
.info p {
  margin-top: 10px;
}
.article .el-divider {
  margin-bottom: 8px;
}
.thead {
  width: 15%;
  line-height: 30px;
  vertical-align: center;
  color: #fff;
  text-align: center;
  /* overflow: hidden; */
  padding: 5px 0;
  font-size: 18px;
  font-weight: 550;
  background-size: cover;
  /* background-position-x: center; */
  /* display: flex;
  align-items: center;
  justify-content: left; */
}
.fhead {
  margin: 8px 0px 0px 0px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 30%; */
  /* text-align: center; */
  vertical-align: center;
  height: 60px;
  color: #fff;
  /* text-indent: 6px; */
  text-align: center;
  font-size: 15px;
  line-height: 15px;
  /* margin-right: -25px; */
  font-weight: 700;
  background-size: contain;
}
.fhead p {
  /* margin-left: 3px; */
  white-space: pre-wrap;
  width: 60%;
  letter-spacing: 1px;
}
.ct .el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.pl_title .el-divider--horizontal {
  width: 40px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.addhf .el-divider--horizontal{
  width: 70px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.plsubmit {
  display: flex;
  align-items: center;
  justify-content: right;
}
.ct p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 2em;
  margin-bottom: 2em;
}
.el-empty {
  padding: 0 !important;
}
.ct .el-empty .el-empty__image {
  width: 100px !important;
}
.sc {
  text-align: right;
}
.el-button--success {
  background-color: #4fc08d;
  /* transition: all 0.3s ease-in; */
}
.el-button--success:hover {
  box-shadow: 0px 2px 15px #a1d7bf;
  /* transition: all 0.3s ease-in; */
  /* text-shadow: 0px 0px 15px #4fc08d; */
}
</style>